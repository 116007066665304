section#banner {
	width: 100%;
	height: 100%;
	z-index: 1;
	.slider {
		.text {
			position: absolute;
			bottom: 0;
			padding: 0 33px 33px;
			
		}
		h1.title {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 2.2rem;
			line-height: 1.1em;
			margin: 0;
			background: linear-gradient(to right, #26348b , #0b983a );
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		h2.contTitle {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 2.2rem;
			background: linear-gradient(to right, #26348b , #0b983a );
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		.slider.changing {
			.text {
				-webkit-transform: translateY(100%);
				transform: translateY(100%);
			}
		}
	}
	.slick-slide {
		img.bannerAlt {
			width: 100%;
			height: 547px;
		}
	}
	.slideCopy-container {
		img.bannerLogo {
			width: 150px;
			height: auto;
		}
	}
	.slideCopy-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 20px;
	}
	.slick-prev {
		width: 35px;
		height: 35px;
		left: 25px;
		z-index: 1001;
		background-color: #26348b;
		padding: 5px;
		border-radius: 40px;
	}
	.slick-next {
		width: 35px;
		height: 35px;
		right: 25px;
		z-index: 1001;
		background-color: #26348b;
		padding: 5px;
		border-radius: 40px;
	}
	.slick-dots {
		li {
			button {
				&::before {
					font-size: 15px;
				}
			}
		}
	}
	p {
		font-size: 1.6rem;
		line-height: 1.1;
	}
}

@media only screen and (max-width:2600px) {
	section#banner {
		.slider {
			.text {
				position: absolute;
				top: 300px;
				bottom: 0;
				left: 100px;
				float: left;
			}
			.text2 {
				position: absolute;
				top: 300px;
				bottom: 0;
				right: 100px;
				float: left;
			}
		}
	}
}
@media only screen and (max-width:1600px) {
	section#banner {
		.slider {
			.text {
				top: 220px;
			}
			.text2 {
				top: 220px;
			}
		}
	}
}
@media only screen and (max-width:1350px) {
	section#banner {
		.slider {
			.text {
				bottom: 0;
			}
			.text2 {
				bottom: 0;
				right: 100px;
				float: left;
			}
		}
	}
}
@media only screen and (max-width:769px) {
	section#banner {
		height: 100%;
		top: 500px;
		.slider {
			img.bannerAlt {
				width: 100%;
				height: 100%;
			}
			h1.title {
				font-size: 2rem;
			}
			h2 {
				font-size: 2rem;
			}
			p {
				font-size: 1rem;
			}
			.text {
				top: 130px;
				right: 0px;
			}
			.text2 {
				top: 130px;
				right: 0px;
			}
		}
	}

}
@media only screen and (max-width:526px) {

	section#banner {
		top: 5%;
		.slideCopy-container {
			img.bannerAlt {
				width: 100%;
				height: 280px;
			}
			img.bannerLogo{
				width: 150px;
				height: auto;
			}
		}
		.slick-prev {
			display: none !important;
		}
		.slick-next {
			display: none !important;
		}
		.slider {
			.text {
				position: absolute;
				top: 100px;
				bottom: 0;
				left: 10px;
				padding: 0 10px 5px;
				z-index: 5;
				-webkit-transition: all .2s ease 0s;
				transition: all .2s ease 0s;
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}
			.text2 {
				position: absolute;
				top: 10px;
				bottom: 0;
				right: 10px;
				padding: 0 10px 5px;
				z-index: 5;
				-webkit-transition: all .2s ease 0s;
				transition: all .2s ease 0s;
				-webkit-transform: translateY(0);
				transform: translateY(0);
			}
			h1.title {
				font-size: 1.1rem;
				margin: .4rem 0 0;
				-webkit-transition: all .6s ease 0s;
				transition: all .6s ease 0s;
			}
			h2.contTitle {
				font-size: 1.1rem;
				background: linear-gradient(to right, #26348b, #0b983a);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			p {
				font-size: 1rem;
			}
		}
	}

}
@media only screen and (max-width:376px) {
	section#banner {
		margin-top: 30%;
		.slideCopy-container {
			img.bannerAlt {
				height: 100%;
			}
		}
		.slider {
			.text {
				top: 30px;
				bottom: 0;
				left: 15px;
				padding: 0 10px 20px;
			}
			p{
				margin-bottom: 0.5rem;
			}
		}
	}
}

@media only screen and (max-width:321px) {
	section#banner {
		margin-top: 38%;
		.slideCopy-container {
			img.bannerAlt {
				height: 100%;
			}
		}
		.slider {
			.text {
				top: 5px;
			}
		}
	}
}
